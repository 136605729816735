<template>
  <v-card elevation="0" tile>
    <status-bar>
      <v-system-bar height="30" color="primary" class="white--text elevation-5">
        <div class="d-flex justify-space-between align-center wide">
          <div>{{ title }}</div>
          <div>
            <v-btn text icon @click="orderUp()">
              <v-icon :disabled="disableUp" color="white">
                mdi-chevron-up
              </v-icon>
            </v-btn>
            <v-btn text icon @click="orderDown()">
              <v-icon :disabled="disableDown" color="white">
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-system-bar>
      <v-system-bar height="5" color="accent" class=""></v-system-bar>
      <v-card-text class="text--primary text-body-1">
        <slot></slot>
      </v-card-text>
    </status-bar>
  </v-card>
</template>

<script>
import StatusBar from "../components/StatusBar.vue";

// @ is an alias to /src
export default {
  name: "ListCard",
  data: () => ({}),
  props: {
    title: {
      type: String
    },
    disableUp: {
      type: Boolean,
      default: false
    },
    disableDown: {
      type: Boolean,
      default: false
    }
  },
  components: {
    "status-bar": StatusBar
  },
  methods: {
    orderUp() {
      if (!this.disableUp) {
        this.$emit("orderup");
      }
    },
    orderDown() {
      if (!this.disableDown) {
        this.$emit("orderdown");
      }
    }
  },
  mounted() {},
  computed: {}
};
</script>
